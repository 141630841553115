<template>
  <div class="container custom-container">
    <b-form @submit.prevent="handleLogin()">
      <b-form-group
        label="Correo electrónico"
        label-for="email"
        description="Ingresa tu correo electrónico"
      >
        <b-form-input id="email" type="email" v-model="email"></b-form-input>
      </b-form-group>
      <b-form-group
        label="Contraseña"
        label-for="password"
        description="Ingresa tu contraseña"
      >
        <b-form-input
          id="password"
          type="password"
          v-model="password"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary">Ingresar</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },

  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async handleLogin() {
      if (!this.formValid) {
        this.$bvToast.toast(
          "Por favor, ingresa un correo electrónico y una contraseña",
          {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 5000,
          }
        );
        return;
      }

      await this.login({ email: this.email, password: this.password });
    },
  },
  computed: {
    formValid() {
      return this.email.length > 0 && this.password.length > 0;
    },
  },
};
</script>

<style scoped>

.custom-container{
  margin-top: 150px;
  padding-bottom: 20px;
}

</style>
